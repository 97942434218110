export const environment = {
  production: true,
  tokenIndex: 'callwaiter_token',
  currentUserIndex: 'callwaiter_current_user',
  profileCompletedIndex: 'callwaiter_profile_completed',
  tokenPrefix: 'Bearer ',
  tokenHeader: 'Authorization',
  powertable: true,
  // dns: 'https://api.guestwaiter.com/api/',
  // cdn: 'https://api.guestwaiter.com/',
  // website: 'https://guestwaiter.com/',

  // dns: 'https://developapi.callwaiter.fr/api/',
  // cdn: 'https://developapi.callwaiter.fr/'
  // website: 'https://develop.callwaiter.fr/'

  dns: 'https://api.guestwaiter.com/api/',
  cdn: 'https://api.guestwaiter.com/',
  website: 'https://guestwaiter.com/',
  webapp: 'https://app.guestwaiter.com/'
};
